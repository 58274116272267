.dct{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.dctform{
    padding: 0px 15px;
    padding-top: 30px;
    
}

.dct_heading{
    border: 1px solid lightgray;
    /* height: 15%; */
   /* margin-bottom: 5px; */
}

.dct_heading>p{
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 13px;
}

.dct_data{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 72px;
    margin-top: 10px;
    margin-bottom: -50px;
    margin-left: 10px;
    margin-right: 10px;
    height:100%;
    border: 3px solid lightgray;
}

.dct_data_heading{
    display: flex;
    justify-content: space-between;
}

.dct_footer{
    display: flex;
    justify-content: flex-end;
}

.footer{
    display: flex;
    
}

