.ticker-message{
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    
    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;   
  }
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(100%); }
    to { -moz-transform: translateX(-100%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(100%); }
    to { -webkit-transform: translateX(-100%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  
  } 

  .ticker-message:hover{
    -webkit-animation-play-state:paused;
    -moz-animation-play-state:paused;
    -o-animation-play-state:paused;
    animation-play-state:paused;
    cursor: pointer;
  }
 
  .k-icon-32 {
    font-size: 32px;
    color: #2D6DF6 !important;
  }

  .header-line {
    width: 100%;
    margin: 0;
    z-index: 1;
    position: relative;
    top: 0;
    border-top: 1px solid #2D6DF6;
    box-shadow: 0px 4px 8px 0px #00000033;
  }

  .store-count {
    color: #060A45;
    font-size: 32px;
    margin-bottom: 5px;
    padding: 0;
    height: 32px;

    &.success {
      color: #008A28;
    }

    &.error {
      color: #AA213F;
    }
  }

  label {
    color: #4F4F4F;
  }