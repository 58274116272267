.actionbutton-div {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 3px;
  cursor: pointer;
}

.actionbutton-div:hover {
  background-color: #0000001A;
}
