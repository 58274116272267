.newMessage_grid {
  //   display: flex;
  height: 62%;
  border-bottom: 1px solid lightgrey;
  font-weight: bolder;
  font-size: 11px;
  overflow-y: auto;
}
.message_row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  width: 100%;
  background-color: #fff;
  border: none;
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #E7EEFF;
    cursor: pointer;
  }
  &.selected {
    background-color: #BDD1FC;
  }
}

.k-window-content {
  > div ol div:first-child {
    background-color: #CDDCFD !important;
    border: none !important;
    margin-right: 10px;

    > span {
      color: #262626 !important;
      left: 44px !important;
      top: 42px !important;
    }
  }
}