.fillHandle {
    width: 7px;
    height: 7px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #000000;
    cursor: crosshair;
  }
.editedCell {
    /* width: 10em;
    height: 6em; */
    /* border: 4px solid #ccc; */
    /* background-color: #fff; */
    position: relative;
}

.editedCell::before,
.editedCell::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    border-style: solid;
}

/* .editedCell::before {
    border-width: 0.5em;
    border-right-color: #ccc;
    border-top-color: #ccc;
} */

.editedCell::after {
    /* border-radius: 0.4em; */
    border-width: 0.25em;
    border-left-color: red;
    border-top-color: red;
}