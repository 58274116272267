.header-tab {
  padding: 15px;
  cursor: pointer;
  border-bottom: 2px solid #fff;
  color: #060A45;
  font-size: 16px;
}
.header-tab:hover,
.header-tab.header-active-tab {
  border-bottom: 3px solid #2D6DF6;
  font-weight: 700;
}
