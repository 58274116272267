.navMenu .k-drawer-wrapper {
  max-height: 470px;
  margin: auto;
  padding-top: 50px;
}

.navMenu {
	>.k-widget {
		box-shadow: 0px 4px 8px 0px #00000033;
		&:hover{
			overflow: visible !important;
		}
	}
	.k-state-selected[aria-selected="true"] {
		align-self: flex-start;
	}
	.k-drawer-item {
		z-index: 9;
    // background-color: red;
    align-self: flex-start;
		&:hover {
			align-self: unset;
      .onHover {
        display: block!important;
      }
		}
    
	}
}
.navMenu.k-drawer-expanded {
	.k-drawer-item {
		align-self: unset;
	}
}

.menuItem .onHover{
  display: none!important;
}
// .navMenu .k-drawer-item:hover .onHover {
//   display: block!important;
// }
.menuItem{
  display: flex;
  align-items: center;
}

.forBadge .k-top-end, .k-pos-top-end {
    top: 10px;
    right: 7px;
	border: 1px solid #4F4F4F;
	border-radius: 50%;
}

.notify-badge {
	color: #fff !important;
}

.k-drawer-wrapper {
	overflow-y: hidden !important;
}


