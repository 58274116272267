.selected-cell {
  border: 1px solid #2b7080 !important;
  color: #000;
  background-color: #8cd7e4;
}

.fillHandle {
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #000000;
  cursor: crosshair;
}

.k-list-horizontal .k-radio-item {
  margin: 0 16px 0 0 !important;
}

.k-icon-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.editedCell {
  position: relative;
}

.editedCell::before,
.editedCell::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-color: transparent;
  border-style: solid;
}

.editedCell::after {
  border-width: 0.25em;
  border-left-color: red;
  border-top-color: red;
}

.k-radio {
  color: #2D6DF6 !important;
}

.wo-header {
  display: "flex";
  align-items: "center";
  background-color: "#ffffff";
  position: relative;
  z-index: 1;
}