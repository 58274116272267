.resourceList-row {
  display: flex;
  //   align-items: center;
  gap: 0.5em;
  padding: 1em;
  cursor: pointer;
  &:hover {
    background-color: #E7EEFF; //#b9f9ff
  }
  &.selected-resource {
    background-color: #BDD1FC;
  }
}
