.navMenu {
    width: 100%;
    height: calc(100vh - 2.8rem - 1px);

    .k-drawer {
        min-width: 3.5rem;
        color: #4F4F4F !important;
        background-color: #fff !important;
    }

    .k-drawer-wrapper {
        height: 100%;
    }

    .k-drawer-items {
        .k-icon {
            font-size: 1.25rem !important;
            line-height: 2 !important;
            height: 2.5rem !important;
        }

        .k-item-text {
            align-items: center;
            display: flex;
        }

        .resource-icon {
            background-image: url(../../assets/images/resource.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .permission-icon {
            background-image: url(../../assets/images/permission.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .freelancer-icon {
            background-image: url(../../assets/images/freelancer.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .qct-responsibility-icon {
            background-image: url(../../assets/images/qct_responsibility.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .qct-hub-icon {
            background-image: url(../../assets/images/qct_hub.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .store-owner-icon {
            background-image: url(../../assets/images/store_owner.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .company-icon {
            background-image: url(../../assets/images/company.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .leaves-icon {
            background-image: url(../../assets/images/leaves.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .company-icon {
            background-image: url(../../assets/images/company.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }

        .resource-availability-icon {
            background-image: url(../../assets/images/resource_availability.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0.5rem;
        }
    }

    .k-drawer-content {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

.transition {
    transition: all 200ms ease 0s;
    border-right: 1px solid;
}

.nielsen-mainview {
    overflow: auto;
    flex-grow: 1;
    background-color: #f2f2f2;
}

.catalog .filter-container {
    max-height: 8.6rem;
}

.filter-container {
    margin: 0.375rem;
    flex-grow: 1;
    display: flex;
    overflow: auto;
    flex-wrap: wrap;

    .k-textbox-container {
        margin: 3px 0.375rem;
        width: 16rem !important;
    }

    .k-form-field {
        width: 16rem !important;
        margin: 0rem 0.625rem 0.375rem 0rem !important;
    }
}

.flex-container {
    padding: 0.375rem;
    display: flex;
    overflow: auto;
    flex-wrap: wrap;

    .k-textbox-container {
        margin: 0.375rem 0.75rem;
        width: 16.4rem !important;
    }

    .k-form-field {
        margin: 0.625rem 0.375rem 0rem 0.625rem;
        width: 16rem;
    }
}

.nielsen-footer {
    display: flex;
    justify-content: center;
    margin: 0px;
    height: 20px;
    font-size: 10px;
    font-weight: normal;
    max-height: 20px;
    padding-top: 10px;
    color: #000;
    background-color: #d9d9d9;
    position: fixed;
    bottom: 0;
    width: 100%;
}
