.header-tab {
  padding-top: 6px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 2px;
}

.message-content {
  > div:first-child {
    border-top: 8px solid #E7EEFF !important;
  }

  > div ol div:first-child {
    background-color: #CDDCFD !important;
    border: none !important;
    margin-right: 10px;

    > span {
      color: #262626 !important;
      left: 50px !important;
      top: 42px !important;
    }
  }
}