.basicInfo_container {
  display: flex;
  flex-direction: column;
  //   gap: ".1em;
  overflow-y: auto;
  padding-block: 0.5em;

  & .row {
    display: flex;
    gap: 1em;
    border-top: 1px solid #ceeaf4;
    padding-block: 0.5em;

    & > div {
      flex-basis: 150px;
      & > div {
        // padding: 0.5em 0;
        height: 2em;
        display: flex;
        align-items: center;
      }
    }
  }
  & .row:first-child {
    border: none;
  }
}
