/* .k-icon-size{
    font-size: 20px !important;
    cursor: pointer;
} */

.accordion {  
    list-style: none;
    padding: 10px;
    margin-top: 15px;
    padding-top: 0;
    /* overflow: scroll;    */
  }
  .accordion .accordian-item{
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
  }
  .accordion-item {
    list-style: none;
    margin: 10px;
  }
  .accordion-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: rgba(0,0,0, 0.3) 0 0 8px;
    border-radius: 5px;         
    /* margin: 20 10 0 10; */
    /* background-color: #ccc; */
    /* padding: 1em;     */
    padding: 5px;
    align-items: center;
}
  
  /* .accordion-toggle:hover {
    background-color: #ddd;
  } */
  .accordion-toggle h3 {
    border-left: 5px solid #2C6CF5;
    padding: 4px;
    font-family: "Aktiv Grotesk", NotoSans, "Montserrat Regular", Sans-Serif;
    /* padding: 2px 3px 2px 10px; */
    font-size: 20px;   
    font-weight: normal; 
  }
  .accordion-toggle span{
    padding: 20px;
  }
  .accordion-content {       
    border-radius: 1.5px;
    box-shadow: 0 2px 8.5px 0 rgb(0 0 0 / 15%);
    border: solid 0.5px rgba(48,48,48,0.14);
    background-color: #fff;
    cursor: default;
    padding: 30px;   
    /* background-color: #fed9ff; */
        /* width: 600px; */
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        padding: 20px;
    
   
  }

  
  .reports-scroll {
    /* background-color: #fed9ff; */
        /* width: 600px; */
        max-height: 100%;
        /* overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        padding: 20px; */
        overflow-y: auto;
        padding: 5px;
  }
   
  



