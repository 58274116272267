.title-nrps{
  background-color: #000 !important;
}
.title-nrps .k-window-content{
    padding: 0 !important;
}
/* .title-nrps .k-window-titlebar{
  padding: 3px !important;
}
.title-nrps .k-window-title{
  padding: 10 !important;
} */
/* .k-i-window-restore::before {
    content: "" !important;    
    background: #fff !important;
} */
.card-sidebar{
    padding: 4px !important;
    border: 1px solid #e3e6f0;
    border-radius: 0.35rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}
.tabs {  
    display: flex;  
  }
  .tab {  
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #333333;
    color: #fff;
    /* background: lightsteelblue;  */
    /* background: #fff; */
    /* padding: 2em 0;   */
    /* padding: 4px; */
    border: none;
  }
  .tab:not(:last-child) {
    /* border-right: 1px solid steelblue;   */
    /* border-right: rgb(0, 240, 0); */
  }
  .tab.active {
    border-bottom:3px solid rgb(0, 240, 0) !important ;
    background: #333333;
    /* background: steelblue; */
    /* background: rgb(0, 240, 0); */
    /* font-weight: bold; */
  }
  .panel {
    display: none;
    background-color: #eaeaea;
    padding: 10px;
  }
  .panel.active {
    display: block;
  }  
  .toggle-btn{
    cursor: pointer;    
    background: transparent;
    border: none;
  }

  .k-btn-search{
    background-color: #333 !important;
    margin-top: 20px;
    color: #fff !important;
  }
  .k-btn-search:hover{
    background-color: #00f000 !important;    
    color: #000 !important;
  }
  .back-to-reports-btn{
    position: absolute;
    right: 0;
    /* bottom: 5px; */
    width: 115px;
    height: 25px;
    z-index: 1;
    background: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.13rem;
    line-height: 1.5;
  }
 
  
  