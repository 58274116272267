/* NO RECORDS IN GRID */
/* .k-grid-norecords {
  text-align: start;
} */

.nrps-nav {
  display: flex;
  justify-content: space-between;
  background: #fff;
  z-index: 1;
  align-items: center;
}

.tab-btn {
  display: inline-flex;
  flex-grow: 1;
  justify-content: center;
  z-index: 1;
  height: 43px
}

.tab-navbar {
  display: flex;
}

.tab-title {
  display: flex;
}

.report-section {
  display: flex;
}

.all-circles {
  display: flex;
  width: 60%;
  justify-content: space-around;
}

.particain {
  position: relative;
  top: 5px;
  border: 1.5px solid #2D6DF6;
  border-radius: 100%;
  height: 116px;
}

.allTab-form {
  padding: 8px 5px 10px;
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.circle {
  border: 5px solid green;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 5px;
}

.inner-circle {
  margin: 0.38rem;
  border: 5px solid green;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-circle-name {
  position: absolute;
  top: 148px;
  font-size: 11px;
}

.form-container {
  gap: 5px;
  display: flex;
  justify-content: space-between;
}

.form-container-pl {
  gap: 5px;
  display: flex;
  justify-content: space-between;
}

.gird-init {
  margin: 0px 5px;
  margin-top: 10px;
}

.gird-init-plan {
  margin: 0px 5px;
}

.form-container .k-form-field {
  margin-top: 0 !important;
}

.flex-container-div {
  background-color: DodgerBlue;
  color: white;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.search-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* ***************************************************** */

.left-arrow {
  font-size: 23px;
}

.topBar {
  height: 115px;
  display: flex;
  flex-direction: column;
}

.menuBar {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: flex-start;
  position: fixed;
  top: 0px;
  z-index: 1;
}

.navIcon {
  width: 38px;
  display: flex;
  margin-right: 4rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.sideMenu {
  left: 0;
  top: 47px;
  height: 100%;
  width: 260px;
  position: fixed;
  background: rgb(249, 249, 249);
  transition: all 0.35s ease-out;
  z-index: 10;
  border-right: 1px solid #2D6DF6;
}

.sideMenu a {
  display: flex;
  align-content: flex-start;
  text-decoration: none;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  padding: 1rem 1.6rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sideMenu a:hover {
  color: rgb(40, 0, 148);
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.nrps-navbar {
  display: flex;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>RangeSlider>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.slider_name {
  position: relative;
  top: 2px;
}

.container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #2D6DF6;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #2D6DF6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: 4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}