.lastetWorkOrder-container {
    height: 82.5%;
    overflow-y: auto;
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    & > button {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #fff;
      padding: 0.5em 1em;
      border: none;
      gap: 7px;
      
      border-bottom: 1px solid #BDD1FC;
      cursor: pointer;
      &:hover {
        background-color: #E7EEFF;
      }
    }
  }
  