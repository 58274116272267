.k-window-title {
    color: #060A45;
}

.upc-counts-header{
    height: 45px;
    color: #060A45;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px;
}