.ts-nso{
    display: flex;
    flex-direction: column;
    height:100%;
}

.ts-nsoform{
    padding: 0px 15px;
    padding-top: 15px;
}

.timestamp_heading{
    border: 1px solid lightgray;
}

.timestamp_heading>p{
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 13px;
}

.ts-nso_data{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 72px;
    margin-top: 10px;
    margin-bottom: -50px;
    margin-left: 10px;
    margin-right: 10px;
    height:100%;
    border: 3px solid lightgray;
}

.ts-nso_data_heading{
    display: flex;
    justify-content: space-between;
}

.ts-nso_footer{
    display: flex;
    justify-content: flex-end;
}

.ts-footer{
    display: flex;
    
}

