@import "~@arcgis/core/assets/esri/themes/dark/main.css";
@import "nielseniq-react-theme";
@import 'react-toastify/dist/ReactToastify.css';

.activeFiltered{
    background-color: #BDD1FC;
}

.k-stepper .k-step-label .k-step-text {
    max-width: calc(11em - (16px + 0.5rem));
}

.k-window{
    z-index: 10002 !important;
}

.learningTabs {
    flex-grow: 1;
    display: flex;
    margin: 0.375rem 0rem 0.625rem 0.625rem;

    li:last-child {
        margin-left: auto !important;
    }
}

.k-popover {
    border-radius: 6px;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    font-family: inherit;
    line-height: 1.4285714286;
    display: flex;
    flex-flow: column nowrap;
    z-index: 12000;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-popover *,
.k-popover *::before,
.k-popover *::after {
    box-sizing: border-box;
}

.k-popover-inner {
    position: relative;
    border-radius: inherit;
}

.k-popover-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 12px 16px;
    border-width: 0 0 1px;
    border-style: solid;
}

.k-popover-body {
    padding: 16px 16px;
}

.k-popover-actions {
    border-width: 1px 0 0;
}

.k-popover-callout {
    margin: 0;
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-style: solid;
    position: absolute;
}

.k-popover-callout.k-callout-n {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.k-popover-callout.k-callout-e {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) rotate(-45deg);
}

.k-popover-callout.k-callout-s {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
}

.k-popover-callout.k-callout-w {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

.k-popover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-popover-inner {
    background-color: inherit;
    border-color: inherit;
}

.k-popover-header {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}

.k-popover-callout {
    border-color: white;
    background-color: inherit;
    box-shadow: "none";
}

.k-anchor {
    color: $primary-button-bg;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 700;

    &:hover {
        color: $primary-button-hovered-bg;
    }

    &:active {
        color: $primary-button-pressed-bg;
    }
}

.nielsen-card {
    border: 1px solid rgb(202, 202, 202);
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
}

.k-pager {
    background-color: #F2F2F2;
    border: none
}

.niq-refresh-button {
    margin-left: 5px;
    width: 15px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.k-dialog-wrapper .k-dialog, .k-widget.k-window {
    border: 1px solid #8D8D8D;
    box-shadow: 0px 2px 16px 0px #00000033;
    border-radius: 4px;
}

.k-window-titlebar, .k-dialog-buttongroup {
    border: none;
}

.k-grid-header {
    padding-right: 7px;
}

.k-pager-sizes .k-dropdown {
    width: 7em;
}

.k-switch {
    font-size: 5px;
}
  
.k-switch-handle {
    width: 20px;
    height: 18px;
    margin-left: 3px;
}
  