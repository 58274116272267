.teamMate:after {
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    clear: both;
    visibility: hidden;
}
.teamMate h2 {
    font-size: 1.3em;
    font-weight: normal;
    padding-top: 17px;
    margin: 0;
}
.teamMate p {
    margin: 0;
    font-size: .8em;
}
.teamMate img {
    display: inline-block;
    vertical-align: top;
    width: 50px;
    height: 50px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 50%;
}
.mate-info {
    display: inline-block;
    vertical-align: top;
}
.panelbar-wrapper {
    /* max-width: 300px; */
    margin: 0 auto;
}
/* .accordion-pannel-p-5 .k-link{
    padding: 5px !important;
} */
.my-5{
    margin-bottom: 5px;
    margin-top: 5px;
}
.col-md-4{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border-right: 5px solid #666; */
    /* padding: 6px; */
    gap: 20px;
    flex-grow: 1;
    justify-content: space-evenly;
    align-items: center;
    /* margin-top: 30px; */
    /* margin-top: 23px; */
}
.col-md-4-btns{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    padding: 10px;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
}
.dispaly-it{
    position: absolute;
    bottom: 65px;
}
.col-beforeFirst-circle{
    border : 5px solid cyan;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px
}
.col-innerBeforeFirst-circle{
    margin: 5px;
    border : 5px solid cyan;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.col-first-circle{
    border : 5px solid green;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px
}
.col-innerFirst-circle{
    margin: 5px;
    border : 5px solid green;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.col-second-circle{
    border : 5px solid yellow;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px
}
.col-innerSecond-circle{
    margin: 5px;
    border : 5px solid yellow;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.col-third-circle{
    border : 5px solid red;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px;
        
    
}
.col-innerThird-circle{
    margin: 5px;
    border : 5px solid red;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.col-fourth-circle{
    border : 5px solid rgb(152, 149, 149);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px;
        
    
}
.col-innerFourth-circle{
    margin: 5px;
    border : 5px solid rgb(152, 149, 149);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.col-fifth-circle{
    border : 5px solid rgb(132, 193, 193);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 10px;
        
    
}
.col-innerFifth-circle{
    margin: 5px;
    border : 5px solid rgb(132, 193, 193);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.btn-fill{
    width: 87px;
    height: fit-content;
    margin: 1px;
}
.btn-empty{
    height: 21px;
    margin: 1px;
    /* width: 50px;
    height: fit-content;
    background: #c2c2c2 !important;
    color: #c2c2c2!important; */
    
}
.tab-form{
    display: flex;
    gap:10px;
    padding: 5px
}

.k-tab-search{
    background-color: #333 !important;
    margin-top: 16px;
    color: #fff !important;
    height: fit-content;
  }
  .k-tab-search:hover{
    background-color: #00f000 !important;    
    color: #000 !important;
  }