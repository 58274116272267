.gridrowContainer {
  display: flex;
  &:nth-child(even) {
    background-color: #eee;
  }
  &:hover {
    background-color: #BDD1FC; //#b9f9ff
  }
  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
