.card-container{
    width: 340px;
    border: 1px solid #CCCCCC;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;    
    display: flex;
    flex-direction: column;
    margin: 4px;
}  
.img-style{
    width: 74%;
    padding: 10px;
    height: 130px;
} 
.all-reports-name {
    font-size: 23px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.1px;
    color: #353535;
    padding: 10px!important;
}
.icon-style{
    
        margin-left: auto;
        padding: 5px;
        margin-bottom: 0;
    
}

.navigate-icon{
    margin-left: auto;
    margin-top: auto;
}

.heading {
    margin-left: 50px;
    margin-top: -32px;
    }

 #back {
    margin-top: 6px;
    margin-left: 1px;
    margin-bottom: -7px;
 }   
 .dctform {
    padding: 25px;
    border: none !important;
    /* border: 1px solid black; */
}
#btn1 {
    margin-top: -8px!important;
    margin-left: 252px;
}
#prebtn {
    margin-top: 1px!important;
    margin-left: 17px;
}
.catalog {
    height: 30%;
    display: flex;
    flex-direction: column;
    /* margin-top: -23px; */
}


.pagination {
    display: inline-block;
    margin-top: -30px;
    margin-left: 1000px;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
  }
  .records{
    margin-top: -15px;
    margin-left: 20px;
  }
  .pagination2 {
    margin-left: 1152px;
    margin-top: -43px;
    color: rgb(20, 2, 2);
   }