.ra-report {
    display: flex;
    flex-direction: column;
}

.ra-form {
    padding: 0px 12px;
}

.export-history-btn-group {
    display: flex;
}