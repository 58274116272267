.capsuleFilter {
  background-color: #BDD1FC;
  padding: 4px 8px;
  border-radius: 30px;
  display: flex;
  white-space: nowrap;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
}

.capsuleFilter:hover {
  background-color: #E7EEFF;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 20%);
}

.search-container {
  background-color: #F2F2F2;
  display: flex;
  gap: 10px;
  padding: 15px;
  padding-top: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}