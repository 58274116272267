.misllaneousReport{
    display: flex;
    flex-direction: column;
    height:100%;
}

.misllaneousReportform{
    padding: 0 15px;
    padding-top: 15px;
}

.miscellaneous_heading{
    border: 1px solid lightgray;
    height: 10%;
    
}

.miscellaneous_heading>p{
    font-size: 22px;
    margin-top: 5px;
    margin-left: 13px;
}

.miscellaneous_data{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 72px;
    margin-top: 10px;
    margin-bottom: -50px;
    margin-left: 10px;
    margin-right: 10px;
    height:100%;
    border: 3px solid lightgray;
}

.miscellaneous_data_heading{
    display: flex;
    justify-content: space-between;
}

.misllaneous_footer{
    display: flex;
    justify-content: flex-end;
}

.footer{
    display: flex;
    
}

/* .k-icon{
    display: block;
            clear: both;
            margin: 0 auto 10px;
            color: #656565;
            font-size: 32px;
} */
.k-combobox{
    /* height:33px !important; */
}

.k-datepicker{
    /* height:33px !important; */
}
