
.mainDiv{
    margin: 0;
    padding: 0 40px;
    display: flex;
    // background-color: green;
    height: 110%;
    flex-direction: column;
    // background-color: (0,0,0);
    background-image: url('../../assets/img/login_backgrnd.png');
    background-size:cover;
    background-repeat: no-repeat;
    // height: 100%;
    background-position:top;
    // opacity: 1;
}
.header{
    display:flex;
    padding-top: 5px;
    align-items: flex-start;
    justify-content: space-between;
}
.mainContent{
    // background-color: blue;
    // position: relative;
    display: flex;
    height: 90%;
    // border: 1px solid black;
    // justify-content: center;\
    align-items: center;
}
.descriptionDiv{
    // display: flex;
    // background-color: greenyellow;
    font-size: 40px;
    color: white;
    width: 75%;
    // align-items: center;
    // justify-content: center;
}
.formDiv{
    width: 376px;
    padding: 20px;
    background-color: white;
    z-index: 1;
    // height: 40%;
    // width: 25%;
    // padding: 20px;
}
.logInImages{
    display: flex;
}
.peopleMgmtImg{
    // background-color: blueviolet;
    width: 72px;
    margin: 0 20px 0 0;
}
.workOrdersImg{
    // background-color: cadetblue;
    width: 72px;
}

.pswrd .k-textbox:focus-within {
    border-color: #fb087a;
}

// .vendorlistdata .k-form-field{
//     margin-top: 8px !important;
// }