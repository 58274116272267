.nielsen-header {
    color : #060A45;
    height: 43px;
    display: flex;
    line-height: 2;
    padding: 0.125px 0.5rem;
    font-size: 1.25rem;
    flex-direction: row;
    background-color: #fff;
    justify-content: space-between;
    width: calc(100% - 90px);
    position: absolute;
    left: 75px;

    .nielsen-icon {
        background-image: url(../../assets/images/nielsen_logo.svg);
        background-size: cover;
        width: 1.5rem !important;
        height: 1.5rem !important;
        background-position: center center;
        color: black;
        margin: 0.625rem;
    }

    .sessionDiv {
        height: 100%;
        display: flex;
        line-height: normal;
        flex-direction: column;
        padding-left: 0.625rem;
        padding-right: 0.625rem;

        .mainInfo {
            font-size: 1rem;
        }

        .subInfo {
            font-size: 0.8rem;
        }
    }

    .user-icon {
        display: block;
        width: 2.25rem !important;
        height: 2.25rem !important;
        border-radius: 1.125rem;
        margin: 0.25rem;
        background-size: cover;
        background-image: url(../../assets/images/nielsen_logo.svg);
    }
}

.session-user-dropdown {
    margin: 0rem;
    padding: 0rem;
    height: 100%;
    background-color: inherit;
}

.settingIcon {
    height: 2rem;
    margin-top: 0.75rem;
    margin-right: .75rem;
    margin-left: .75rem;
}

.collectionIcon {
    height: 2rem;
    margin-top: 0.25rem;
    margin-right: .75rem;
    margin-left: .75rem;
}